import React from "react"
import PropTypes from "prop-types"
import ClipLoader from "react-spinners/ClipLoader"
import tw from "twin.macro"
import "twin.macro"

const SubmitButton = ({ children, to, loading = false }) => {
  const loadingStyles = tw`opacity-40 pointer-events-none`
  return (
    <button
      type="submit"
      disabled={loading}
      css={[
        tw`inline-block bg-lilac-500 px-7 py-2 font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300`,
        loading && loadingStyles,
      ]}
    >
      {!loading && children}
      {loading && (
        <span tw="px-2">
          <ClipLoader color={"currentColor"} size={20} />
        </span>
      )}
    </button>
  )
}

SubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SubmitButton
